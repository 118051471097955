<template>
    <div>
        <v-dialog v-if="displayDatePicker" v-model="displayDatePicker"
                  :width="dialogWidth" content-class="date-picker" class="py-0">
            <v-card>
                <v-card-text class="px-0 py-0">
                    <v-date-picker ref="date" v-model="computedDate" v-bind="datePickerProps"
                                   :locale="locale" :min="minDate" :max="maxDate"
                                   first-day-of-week="1" full-width>
                    </v-date-picker>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ValidationProvider v-slot="{ errors, valid }">
            <v-text-field v-bind="textFieldProps" :disabled="disabled" :error="!valid"
                          :loading="loading" :label="label" :value="formattedDate"
                          :error-messages="errors" :locale="locale"
                          :color="color" :readonly="readonly" :clearable="clearable"
                          @click.stop="displayDatePicker = !displayDatePicker"
                          @click:clear="clearHandler"
                          hide-details
                          outlined
                          solo
                          flat
                          dense
                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                          clearable>
            </v-text-field>
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
    name: 'DatePicker',
    components: { ValidationProvider },
    model: {
        prop: 'date',
        event: 'input'
    },
    props: {
        dialogWidth: {
            type: [Number, String],
            default: 290
        },
        date: [Date, String],
        disabled: Boolean,
        loading: Boolean,
        label: String,
        dateFormat: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        minDate: String,
        maxDate: String,
        textFieldProps: Object,
        datePickerProps: Object,
        readonly: Boolean,
        clearable: Boolean,
        color: {
            type: String,
            default: 'primary'
        },
        locale: {
            type: String,
            default: 'ru'
        }
    },
    data() {
        return {
            displayDatePicker: false,
            internalDate: this.date
        }
    },
    computed: {
        formattedDate() {
            return this.internalDate ? this.$moment(this.internalDate).format(this.dateFormat) : ''
        },
        computedDate: {
            get() {
                return this.internalDate;
            },
            set(value) {
                this.internalDate = value;
                this.$emit('input', value);
                this.displayDatePicker = false
            }
        }
    },
    methods: {
        clearHandler() {
            this.displayDatePicker = false;
            this.computedDate = null;
        }
    }
}
</script>

<style scoped>
.date-picker .v-picker__title {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.date-picker .v-slide-group__prev.v-slide-group__prev--disabled {
    display: none !important;
}
</style>
