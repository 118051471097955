<template>
    <v-row class="pt-3 pb-5">
        <template v-for="(field, fieldName) in form.fields" >
            <v-col class="py-0" cols="12" md="6" v-if="field.type === 'datetime'">
                <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                    <div class="input_label">{{field.label}}</div>
                    <datetime-picker v-model="formData[fieldName]"
                                     :validator-name="fieldName"
                                     @input="defaultField(formData[fieldName], fieldName)"
                                     :error="!valid"
                                     :validator-rules="field.rules"
                                     format="24hr"
                                     :label="field.label"
                                     :locale="lang"
                                     :timezone="$auth.user().timezone" timeFormat="HH:mm"
                                     readonly
                                     hide-details
                                     :error-messages="errors"
                                     :disabled="loading"
                                     outlined
                                     solo
                                     flat
                                     dense
                                     color="primary"
                                     class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                     clearable></datetime-picker>
                </ValidationProvider>
            </v-col>
            <v-col class="py-0" cols="12" md="6" v-if="field.type === 'date'">
                <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                    <div class="input_label">{{field.label}}</div>
                    <DatePicker v-model="formData[fieldName]"
                                     :validator-name="fieldName"
                                     @input="defaultField(formData[fieldName], fieldName)"
                                     :error="!valid"
                                     :validator-rules="field.rules"
                                     :label="field.label"
                                     :locale="lang"
                                     readonly
                                     hide-details
                                     :error-messages="errors"
                                     :disabled="loading"
                                     outlined
                                     solo
                                     flat
                                     dense
                                     color="primary"
                                     class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                     clearable></DatePicker>
                </ValidationProvider>
            </v-col>
            <v-col class="py-0" cols="12" md="6" v-if="field.type === 'string' || field.type === 'number' || field.type === 'integer'">
                <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                    <div class="input_label">{{field.label}}</div>
                    <v-text-field
                        @focusout="defaultField(formData[fieldName], fieldName)"
                        :error="!valid"
                        :error-messages="errors"
                        :label="field.label"
                        v-model="formData[fieldName]"
                        item-text="name"
                        item-value="id"
                        hide-details
                        outlined
                        solo
                        flat
                        dense
                        color="primary"
                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                        clearable
                    ></v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col class="py-0" cols="12" md="6" v-if="field.type === 'boolean'">
                <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                    <div class="input_label">{{field.label}}</div>
                    <v-select
                        @change="defaultField(formData[fieldName], fieldName)"
                        v-model="formData[fieldName]"
                        :items="itemYN"
                        :error="!valid"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        :label="field.label"
                        hide-details
                        outlined
                        solo
                        flat
                        dense
                        color="primary"
                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                        clearable
                    ></v-select>
                </ValidationProvider>
            </v-col>
            <v-col class="py-0" cols="12" md="6" v-if="field.type === 'select' || field.type === 'enum'">
                <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                    <div class="input_label">{{field.label}}</div>
                    <v-select
                        @change="defaultField(formData[fieldName], fieldName)"
                        v-model="formData[fieldName]"
                        :items="field.items"
                        :error="!valid"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        :label="field.label"
                        hide-details
                        outlined
                        solo
                        flat
                        dense
                        color="primary"
                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                        clearable
                    ></v-select>
                </ValidationProvider>
            </v-col>
            <v-col class="py-0" cols="12" md="6" v-if="field.type === 'list'">
                <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                    <div class="input_label">{{field.label}}</div>
                    <v-select
                        @change="defaultField(formData[fieldName], fieldName)"
                        v-model="formData[fieldName]"
                        :multiple="field.multiple"
                        :items="field.items"
                        :error="!valid"
                        :error-messages="errors"
                        item-text="value"
                        item-value="id"
                        :label="field.label"
                        hide-details
                        outlined
                        solo
                        flat
                        dense
                        color="primary"
                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                        clearable
                    ></v-select>
                </ValidationProvider>
            </v-col>
        </template>
    </v-row>
</template>

<script>

import { ValidationProvider} from "vee-validate";
import DatetimePicker from '@/components/DatetimePicker.vue'
import DatePicker from "@/components/DatePicker";
import {mapGetters} from "vuex";

export default {
    name: 'FormDialog',
    components: {

        ValidationProvider,
        DatetimePicker,
        DatePicker
    },
    props: {
        form: Object,
        edit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            formData: this.initializeFormData(),
            itemYN: [
                {
                    id: -1,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
        };
    },
    computed: {
        ...mapGetters(['lang']),
    },
    methods: {
        initializeFormData() {
            let data = {};
            for (let fieldName in this.form.fields) {
                data[fieldName] = this.form.fields[fieldName].default || '';
            }
            return data;
        },
        defaultField(value, key){
              Object.keys(this.form.fields).forEach(fieldKey => {
                if (fieldKey === key) {
                    this.form.fields[fieldKey].default = value;
                }
            });
        }

    }
};
</script>


